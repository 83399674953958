export const ptBr = {
  startsWith: "Começa com",
  contains: "Contém",
  notContains: "Não contém",
  equals: "Igual",
  notEquals: "Diferente",
  noFilter: "Sem filtro",
  lt: "Menor que",
  lte: "Menor ou igual a",
  gt: "Maior que",
  gte: "Maior ou igual a",
  dateIs: "Data é",
  dateIsNot: "Data não é",
  dateBefore: "Data antes",
  dateAfter: "Data depois",
  clear: "Limpar",
  apply: "Aplicar",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Adicionar Regra",
  removeRule: "Remover Regra",
  accept: "Sim",
  reject: "Não",
  choose: "Escolha",
  upload: "Upload",
  cancel: "Cancelar",
  dayNames: [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ],
  dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  today: "Hoje",
  weekHeader: "Semana",
  firstDayOfWeek: 0,
  dateFormat: "dd/mm/yy",
  weak: "Fraco",
  medium: "Médio",
  strong: "Forte",
  passwordPrompt: "Digite uma senha",
};
